<template>
  <div class="mavon-editor" v-bind:style="{height:theight+'px'}">
    <mavon-editor v-model="myValue" ref=md @save="onBlur" @imgAdd="imgAdd" id="editor" />
  </div>
</template>
<script>
//import mavonEditor from "mavon-editor";
// console.log(mavonEditor)
// import "mavon-editor/dist/css/index.css";
import {
  uploadsImageRequest,
} from "@/api/project.js";
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    theight:{
      type:  [String, Array,Object,Number],
      default: "300"
    },
    toolbars: {
      bold: true, // 粗体
      italic: true, // 斜体
      header: true, // 标题
      underline: true, // 下划线
      strikethrough: true, // 中划线
      mark: true, // 标记
      superscript: false, // 上角标
      subscript: false, // 下角标
      quote: true, // 引用
      ol: true, // 有序列表
      ul: true, // 无序列表
      link: true, // 链接
      imagelink: true, // 图片链接
      code: true, // code
      table: true, // 表格
      fullscreen: true, // 全屏编辑
      readmodel: false, // 沉浸式阅读
      htmlcode: true, // 展示html源码
      help: true, // 帮助
      /* 1.3.5 */
      undo: true, // 上一步
      redo: true, // 下一步
      trash: false, // 清空
      save: false, // 保存（触发events中的save事件）
      /* 1.4.2 */
      navigation: true, // 导航目录
      /* 2.1.8 */
      alignleft: true, // 左对齐
      aligncenter: true, // 居中
      alignright: true, // 右对齐
      /* 2.2.1 */
      subfield: true, // 单双栏模式
      preview: false, // 预览
    }

  },
  data() {
    return {
      myValue: this.value
    };
  },
  created(){

  },
  methods:{
    onBlur(value, render){
       this.htmlValue = render;
        console.log(value, render)
       this.$emit("onBlur", true);
    },
    setHeight(){

      // .contentStyleObj.heightheight('100')
    },
    // 绑定@imgAdd event
    imgAdd(pos, $file){

      // 第一步.将图片上传到服务器.
      var _this = this;
      var formdata = new FormData();
      formdata.append('file', $file);
      uploadsImageRequest(formdata).then(data => {

        // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
        /**
         * $vm 指为mavonEditor实例，可以通过如下两种方式获取
         * 1. 通过引入对象获取: `import {mavonEditor} from ...` 等方式引入后，`$vm`为`mavonEditor`
         * 2. 通过$refs获取: html声明ref : `<mavon-editor ref=md ></mavon-editor>，`$vm`为 `this.$refs.md`
         */
        _this.$refs.md.$imglst2Url([[pos, data.link]])
      });

    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue;
    },
    myValue(newValue) {
      this.$emit("input", newValue);
    }
  }
};
</script>
<style lang="less" scoped>
.mavon-editor {

  .v-note-wrapper.shadow {
    border: 1px solid #ccc;
    box-shadow: none !important;
    border-radius: 0;
    height: 100%;
  }
}
</style>
