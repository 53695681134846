<template>
  <div class="tinymce-editor">
    <editor v-model="myValue" :init="init" :disabled="disabled" @onClick="onClick" @onBlur="onBlur" ></editor>
  </div>
</template>
<script>

import tinymce from "tinymce/tinymce";
import "tinymce/themes/silver/theme";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver";
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/plugins/image'// 插入上传图片插件
//import 'tinymce/plugins/media'// 插入视频插件
import "tinymce/plugins/table"; // 插入表格插件
import "tinymce/plugins/lists"; // 列表插件
import "tinymce/plugins/link"; // 列表插件
import "tinymce/plugins/paste"; // 列表插件
import "tinymce/plugins/codesample"; // 列表插件
import "tinymce/plugins/wordcount"; // 字数统计插件
import "tinymce/icons/default";

import {
  uploadsImageRequest,
} from "@/api/project.js";

export default {
  name: "tinymceEditor",
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: ''
    },
    plugins: {
      type: [String, Array],
      default: "lists link table paste image wordcount codesample "
    },
    toolbar: {
      type: [String, Array],
      default:
        "undo redo | formatselect fontsizeselect| bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | blockquote lists link image media table removeformat codesample"
    },
    theight:{
      type:  [String, Array, Object, Number],
      default: "300"
    }
  },
  data() {
    return {
      init: {
        language_url: process.env.BASE_URL+"tinymce/zh_CN.js", //public目录下
        language: "zh_CN",
        skin_url: process.env.BASE_URL+"tinymce/skins/ui/oxide", //public目录下
        content_css: process.env.BASE_URL+"tinymce/skins/content/default/content.min.css",//public目录下css process.env.BASE_URL是因为打包以后路径问题
        height: this.theight,
        plugins: this.plugins, // 父组件传入 或者 填写个默认的插件 要选用什么插件都可以 去官网可以查到
        toolbar: this.toolbar, // 工具栏 我用到的也就是lists image media table wordcount 这些 根据需求而定
        menubar:false,
        images_upload_url: "/uploads/image", //上传路径
        paste_data_images: true, // 设置为“true”将允许粘贴图像，而将其设置为“false”将不允许粘贴图像.
        codesample_languages: [
          { text: 'HTML/XML', value: 'markup' },
          { text: 'JavaScript', value: 'javascript'},
          { text: 'CSS', value: 'css' },
          { text: 'Golang', value: 'go' },
          { text: 'Python', value: 'python' },
          { text: 'Java', value: 'java' },
          { text: 'PHP', value: 'php' },
          { text: 'Shell', value: 'shell' },
          { text: 'Ruby', value: 'ruby' },
          { text: 'Lua', value: 'lua' },
          { text: 'C', value: 'c' },
          { text: 'C#', value: 'csharp' },
          { text: 'C++', value: 'cpp' },
          { text: 'kotlin', value: 'kotlin' },
        ],
        // 官网抄的图片上传 项目如果用了vue-resource可以用$http 因为比较懒就没改
        images_upload_handler: (blobInfo, success, failure) => {

          var formData = new FormData();
          formData.append("file", blobInfo.blob(), blobInfo.filename());
          uploadsImageRequest(formData).then(data => {
            success(data.link);
          });
        }
      },
      myValue: this.value
    };
  },
  mounted() {
    tinymce.init({});
  },
  methods: {
    onClick(e) {

      this.$emit("onClick", e, tinymce);
    },
    onBlur(){
      this.$emit("onBlur", true);
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue;
    },
    myValue(newValue) {
      this.$emit("input", newValue);
    }
  }
};
</script>
